<template>
  <section class="tracking-wrap">
    <section class="tracking-content">
      <div id="div1">
        <div >
          <div class="items">
            <label class="title" style="position: relative;">生成时间：</label>
            <el-radio-group v-model="form.pubDate" size="mini" @change="changeHandler">
              <el-radio label="1" border>今天</el-radio>
              <el-radio label="2" border>最近三天</el-radio>
              <el-radio label="3" border>最近七天</el-radio>
              <el-radio label="4" border>最近三十天</el-radio>
              <el-radio label="5" border @click.native="pubDateStyle.display = 'inline-block'">自定义</el-radio>
              <el-date-picker v-model="form.pubDateFrame" type="datetimerange" size="mini" range-separator="--" unlink-panels
                start-placeholder="开始日期" end-placeholder="结束日期" :style="pubDateStyle" @blur="pubDateStyle.display = 'none'">
              </el-date-picker>
            </el-radio-group>
          </div>


          <div class="items">
            <label class="title">情感类型：</label>
            <el-radio-group id="emotion" v-model="form.emotionType" size="mini">
              <el-radio label="-1" border style="margin-right: 1px">全部</el-radio>
              <el-radio label="0" border style="margin-right: 1px">正面</el-radio>
              <el-radio label="1" border style="margin-right: 1px">负面</el-radio>
              <el-radio label="2" border>敏感</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div>
          <div class="items">
            <label class="title">关键词：</label>
            <el-input size="mini" placeholder="请输入关键词"  v-model="form.keyWord"></el-input>
          </div>

        </div>
        <div class="btn-wrapper" style="text-align: center;font-size: 14px;margin-bottom: 10px;">
          <el-button size="mini" type="primary" id="searchBtn" @click="search" :disabled="loading">查询</el-button>
          <el-button size="mini" @click="reset" :disabled="loading">重置</el-button>
          <el-button size="mini" type="primary" @click="showCheckFlag = true;form.name=''" :disabled="loading">保存筛选条件</el-button>
          <el-select size="medium" style="margin-left: 10px" v-model="checkIndex" filterable placeholder="保存条件" @change="GetChecked">
            <el-option v-for="(item,index) in submitArr" :key="index" :label="item.name " :value="index">
            </el-option>
          </el-select>
        </div>
      </div>
      <div id="div2" v-loading="loading">
        <div id="div3">
          <el-button type="primary" size="mini" @click="batchSetunTrack">停止追踪</el-button>
          <!-- <div id="div4">
            <el-radio-group v-model="viewType" size="mini">
              <el-radio-button label="1"><i class="el-icon-menu"></i></el-radio-button>
              <el-radio-button label="2"><i class="el-icon-s-grid"></i></el-radio-button>
            </el-radio-group>
          </div> -->
        </div>
        <div id="div5">
          <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" element-loading-text="正在查询"
            @selection-change="handleSelectionChange">
            <el-table-column :resizable="false" type="selection" align="center" width="60">
            </el-table-column>
            <el-table-column  :resizable="false" prop="emotion" label="情感" align="center" width="100">
              <template slot-scope="scope">
                <el-tag size="mini" effect="dark" :type="scope.row.isDanger === 0 ? 'defult' : scope.row.isDanger === 1 ? 'danger' : 'warning'">{{ scope.row.isDanger === 0 ? '正面' : scope.row.isDanger === 1 ? '负面' : '敏感' }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" :label="viewType == 1 ? '标题' : '标题+摘要'" prop="title" header-align="center" min-width="220px"
              show-overflow-tooltip>
              <template scope="{ row }">
                <!-- viewType 为2的时候标题和摘要都显示 -->
                <span :class="[viewType == 1 ? 'title' : 'showAbstract',row.classname]" v-html="row.title" @click="getNewsDetailsThroughSn(row.sn)">{{row.title}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column  :resizable="false" prop="emotion" label="发布者" align="center" width="80">
              <template slot-scope="scope">
                <span size="mini" effect="dark">{{ scope.row.authorname }}</span>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" prop="mark" label="标签" align="center" width="180" show-overflow-tooltip>
              <template scope="{ row }">
                <el-tag size="mini" effect="dark" v-for="(item, index) in row.markList" :key="index">{{item}}</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column  :resizable="false" label="来源" align="center" show-overflow-tooltip>
              <template scope="{ row }">
                <span v-if="row.customeSource === null || row.customeSource === ''">{{ row.sourceName }}</span>
                <span v-else>{{ row.customeSource }}</span>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" prop="publishDate" label="生成时间" align="center" width="160" sortable>
              <template scope="{ row }">
                {{formatDate(row.reviewDate)}}
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" label="操作" align="center" width="120">
              <template scope="{ row, $index }">
                <span v-if="row.isTrack === 1" style="cursor: pointer;color: #3472c7;" @click="setUntrack(row)">停止追踪</span>
                <span v-if="row.isTrack === 0" style="cursor: pointer;color: #858585;" >已停止</span>
              </template>
            </el-table-column>
          </el-table>
          <section class="list-bottom">
            <span class="count">共{{count}}条记录</span>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
              :page-sizes="[10, 20, 30, 50]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="count">
            </el-pagination>
          </section>
        </div>
      </div>
      <el-dialog title="保存条件" width="30%" top="25vh" :visible.sync="showCheckFlag">
        <el-form>
          <el-form-item label="条件名称：" class="sort" prop="sorts">
            <el-input v-model="form.name" autocomplete="off" style="width: 220px"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="showCheckFlag = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="onSubmit()">确 定</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>>

<script>
  import {
    listAllSiteType
  } from '@/api/detection'
  import {
    getTrackList,
    setUntrack
  } from '@/api/dataTrack'
  export default {
    data() {
      return {
        loading: false,
        showCheckFlag:false,
        checkIndex:'',
        submitArr: [],
        newindex: 0,
        count: 0, // 共多少条数据
        limit: 10, // 一页多少条数据
        currentPage: 1, // 当前页
        multipleSelection: [], // 选中的表格数据
        viewType: 1, // 视图类型，1：标题   2：标题+摘要
        radio: '1',
        openFilter: true,
        pubDateStyle: {
          display: 'none',
          margin: '0 15px'
        },
        siteTypeList: [],
        form: {
          date1: '',
          date2: '',
          sourceType: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          pubDate: '4',
          pubDateFrame: [], // 时间区间
          emotionType: '-1',
          tag: '-1',
          scope: '1',
          keyWord: '',
          isMerge: '0'
        },
        isTrack: 1,
        tableData: []
      }
    },
    mounted() {
      if (localStorage.getItem(`trackConditions_${this.$store.state.user.name}`)) {
        this.submitArr = JSON.parse(localStorage.getItem(`trackConditions_${this.$store.state.user.name}`))
        if (this.submitArr.length > 0) {
          var len = this.submitArr.length - 1
          this.form = JSON.parse(JSON.stringify(this.submitArr[len]))
          this.checkIndex = this.submitArr.length - 1
        }
      }
      this.listSiteType()
      this.viewShowData()
    },
    watch: {
      'form.pubDateFrame': {
        handler (val) {
          if (val.length > 0) {
            this.form.date1 = this.formatDate(val[0])
            this.form.date2 = this.formatDate(val[1])
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      GetChecked(){
        this.form = this.submitArr[this.checkIndex]
      },
      async viewShowData() {
        this.loading = true
        this.listSN = []
        const res = await getTrackList({
          pageSize: this.limit,
          current: this.currentPage,
          timeType: this.form.pubDate,
          sourceType: this.form.sourceType,
          emotionType: this.form.emotionType,
          isTag: this.form.tag,
          keyWord: this.form.keyWord,
          scope: this.form.scope,
          startDate: this.form.date1,
          endDate: this.form.date2,
          isTrack: this.isTrack
        })
        this.loading = false
        if (res.code === 0) {
          this.tableData = res.data.newsDataList
          this.count = res.count
        } else if (res.code === 1) {
          this.tableData = []
          this.count = 0
        }
      },
      // 提交表单
      onSubmit() {
        if (this.submitArr.length < 5) {
          this.submitArr.push(Object.assign({}, this.form))
        } else {
          /* var index = this.newindex % 5
          this.$set(this.submitArr, index, Object.assign({}, this.form))
          this.newindex += 1 */
          var item = this.submitArr.shift();
          this.submitArr.push(Object.assign({}, this.form));
        }
        localStorage.setItem(`trackConditions_${this.$store.state.user.name}`, JSON.stringify(this.submitArr))
        this.showCheckFlag = false
        this.checkIndex = this.submitArr.length - 1
        this.$message.success('筛选条件保存成功')
      },
      // 删除条件
      removeCondition(index) {
        this.submitArr.splice(index, 1)
        localStorage.setItem(`trackConditions_${this.$store.state.user.name}`, JSON.stringify(this.submitArr))
        this.showCheckFlag = false
        this.checkIndex = this.submitArr.length - 1 >= 0 ? this.submitArr.length - 1 : ''
        this.$message.success('条件删除成功')
      },
      search() {
        this.currentPage = 1
        this.viewShowData()
      },
      reset() {
        this.$refs['form'].resetFields()
        this.form = {
          classifyRule: [],
          date1: "",
          date2: "",
          emotionType: "-1",
          isMerge: '1',
          keyWord: "",
          nodeids: [],
          pubDate: "1",
          pubDateFrame: [],
          region: "",
          scope: "1",
          sourceType: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          tag: -1
        }
        this.pubDateStyle.display = 'none'
      },
      // 取消追踪
      async setUntrack(row) {
        var arr = []
        arr.push(row.sn)

        const res = await setUntrack("list=" + arr)
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.viewShowData()
        } else {
          this.$message.error(res.msg)
        }
      },
      async batchSetunTrack() {
        var arr = []
        if (this.multipleSelection.length === 0) {
          this.$alert('请至少选择一条数据', '提示', {
            confirmButtonText: '确定'
          })
        } else {
          this.multipleSelection.forEach((item, index) => {
            arr.push(item.sn)
          })
          const res = await setUntrack("list=" + arr)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.viewShowData()
          } else {
            this.$message.error(res.msg)
          }
        }
      },
      // 查看详情
      getNewsDetailsThroughSn(sn) {
        const {
          href
        } = this.$router.resolve({
          name: 'dataDetail',
          params: {
            sn: sn
          }
        })
        window.open(href, '_blank')
        // this.$router.push(`monitor/list/${this.$route.params.id}/${this.$route.params.nodeId}`)
      },
      formatDate(time) {
        if (typeof(time) === 'string' && time.indexOf("T") === -1) {
          time = time.replace(/-/g, '/').substr(0, time.indexOf('.'))
        }
        let date = new Date(time)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let min = date.getMinutes()
        let second = date.getSeconds()
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        if (hour < 10) {
          hour = '0' + hour
        }
        if (min < 10) {
          min = '0' + min
        }
        if (second < 10) {
          second = '0' + second
        }
        return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
      },
      // 表格全选
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      handleSizeChange(val) {
        this.limit = val
        this.viewShowData(this.currentPage, val)
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.viewShowData(val, this.size)
      },
      // 监听发布时间选中情况
      changeHandler() {
        if (this.form.pubDate === '5') {
          this.pubDateStyle.display = 'inline-block'
        } else {
          this.pubDateStyle.display = 'none'
          this.form.pubDateFrame = []
        }
      },
      // 收起筛选条件
      dropUp() {
        this.openFilter ? (this.openFilter = false) : (this.openFilter = true)
      },
      async listSiteType() {
        const res = await listAllSiteType()
        this.siteTypeList = res.data
      },
    }
  }
</script>

<style lang="scss">
  .tracking-wrap {
    width: 100%;
    height: 100%;
    // padding: 30px 30px;
    // display: flex;

    .tracking-content {
      width: 100%;

      #div1,
      #div2 {
        width: 100%;
        border-radius: 2px;
        // margin-left: 30px;
        margin-bottom: 30px;
        border: 1px #eceaea solid;
        background: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);
      }

      #div1 {
        padding: 15px 25px;
        .btn-wrapper{
          margin-top: 10px;
        }
        &>div:not(:last-child){
          border-bottom: 1px solid #efefef;
          .items{
            padding: 5px 0;
            display: inline-block;
            position: relative;
            &:first-child{
              width: 60%;
            }
            &:last-child{
              width: 40%;
            }
            .el-input,.el-select{
              width: 230px;
              margin-right: 20px;
            }
          }
          .title{
            // font-weight: 400;
            color: #565656;
            font-size: 15px;

            .el-radio--mini.is-bordered .el-radio__label,
            .el-checkbox__input.is-checked + .el-checkbox__label{
              color: #838383;
            }

          }
        }
        .el-input__inner {
          height: 30px !important;
        }

        .dateContainer {
          color: #000000;
          font-size: 15px;
        }

        .beginTime,
        .endTime {
          font-size: 12px;
        }

        .el-date-editor {
          position: absolute;
          left: 30%;
          bottom: -33px;
          z-index: 1;
        }

        .el-date-editor.el-input__inner {
          height: 35px !important;
        }

            .el-checkbox {
              margin-right: 15px;
            }

            .el-checkbox-group {
              margin-bottom: -3px;
            }
          }

          .el-radio-group {
            margin-bottom: 3px;

            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              margin-left: 20px;
            }

            .el-date-editor--daterange {
              height: 30px !important;
            }
          }

          .el-radio--mini.is-bordered {
            padding: 6px 10px 6px 0px;
            margin-right: 1px;
          }
        .el-radio.is-bordered.is-checked:not(.is-disabled){
          border: 1px solid #3472c7;
        }
        .el-radio__input.is-checked:not(.is-disabled) + .el-radio__label{
          color: #3472c7 !important;
        }
          #emotion {
            padding: 3px 1px 1px 0px;
          }

          #emotion .el-checkbox.is-bordered.el-checkbox--mini {
            padding: 4px 10px 0px 0px;
          }

          #emotion .el-checkbox__input {
            display: none;
          }
          .el-button {
            height: 30px;
            padding-top: 8px;
          }

          #searchBtn {
            margin-left: 12px;
          }

          .el-date-editor .el-range-separator {
            padding-bottom: 11px;
          }

        .el-radio__input {
          display: none;
        }


      }

      #div2 {
        position: relative;
      }

      #div3 {
        padding: 30px 30px 30px 30px;

        .batchBtn {
          position: relative;
        }

        #div4 {
          display: inline-block;
          float: right;
        }

        .el-dropdown {
          margin-right: 10px;
        }

        #div5 {
          border: 1px #eceaea solid;
        }

        .batchMenu {
          width: 100px;
          height: 142px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 27.5px;
          left: -10px;
          z-index: abs($number: 999);

          .menuUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: left;
              padding-left: 15px;
              color: rgb(14, 13, 13);
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .emotionMenu {
          width: 60px;
          height: 100px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 0px;
          left: 101px;
          z-index: abs($number: 999);

          .emotionUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: center;
              padding-left: 0px;
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .openLabel {
          position: relative;

          .mark-wrap {
            width: 300px;
            height: 330px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
            position: absolute;
            top: -17px;
            left: 101%;
            padding: 15px;

            .table-wrap {
              height: 200px;
              border: 1px solid #979797;
              overflow-x: auto;
              overflow-y: auto;

              .mark-table {
                display: flex;

                .table-col {
                  padding: 0;
                  border-right: 1px solid #979797;
                  flex-grow: 1;
                  flex-shrink: 0;

                  &:last-child {
                    border-right: none;
                  }

                  .mark-header {
                    border-bottom: 1px solid #979797;
                  }

                  li {
                    text-align: left;
                    padding-left: 15px;
                    width: 100px;
                  }
                }
              }
            }

            .table-bottom {
              padding-top: 5px;
            }
          }
        }
      }

      .el-table {
        width: calc(100% - 40px);
        margin-bottom: 80px;
        border-radius: 10px;
        margin-left: 20px;
        overflow: visible;

        .el-table__body-wrapper {
          overflow: visible;
        }

        th.gutter {
          display: table-cell !important;
        }

        &.el-table th>.cell {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .caozuo {
          width: 25px;
          height: 25px;
        }

        .oneOperationPanel {
          width: 115px;
          height: 180px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          right: 0px;
          z-index: abs($number: 999);

          .oneOperationUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: left;
              padding-left: 15px;
              color: rgb(14, 13, 13);
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .showOneEmotionPanel {
          width: 60px;
          height: 103px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 0px;
          right: 116px;
          z-index: abs($number: 999);

          .oneEmotionUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: center;
              padding-left: 0px;
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .oneMarkPanel {
          position: relative;

          .mark-wrap-one {
            width: 300px;
            height: 330px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
            position: absolute;
            top: -17px;
            right: 116px;
            padding: 15px;
            border: 1px #e6e0e0 solid;

            .table-wrap-one {
              height: 200px;
              border: 1px solid #979797;
              overflow-x: auto;
              overflow-y: auto;

              .mark-table-one {
                display: flex;

                .table-col-one {
                  padding: 0;
                  border-right: 1px solid #979797;
                  flex-grow: 1;
                  flex-shrink: 0;

                  &:last-child {
                    border-right: none;
                  }

                  .mark-header-one {
                    border-bottom: 1px solid #979797;
                  }

                  li {
                    text-align: left;
                    padding-left: 15px;
                    width: 100px;
                  }
                }
              }
            }

            .table-bottom-one {
              padding-top: 5px;
            }
          }
        }

        .newsGroupPanelOne {
          width: 110px;
          height: 68px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          // top: 207.5px;
          top: 175px;
          right: 116px;
          z-index: abs($number: 999);

          .oneNewsGroupUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: left;
              padding-left: 15px;
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .bottomEllipsis {
          .oneOperationPanel {
            bottom: 32px;
          }
        }
      }

      .el-form {
        padding: 30px 30px 30px 30px;

        .el-input--suffix .el-input__inner {
          height: 30px;
        }
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .count {
          line-height: 32px;
        }
    }

    .dialog_form {
      text-align: left;
    }
  }

  .prompt {
    height: 28px;
    display: flex;
    align-items: center;

    .el-icon-warning {
      font-size: 28px;
      color: #FF8B35;
      margin-right: 12px;
    }
  }

  .el-tree-node__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  #div5 {
    .title {
      width: 100%;
      color: #409EFF;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      cursor: pointer;

      &.all {
        width: 50%;
      }

      &.tag2 {
        width: 65%;
      }

      &.one {
        width: 85%;
      }
    }

    .childCount {
      // color: #409EFF;
      cursor: pointer;
    }

    .showAbstract {
      width: 90%;
      color: #409EFF;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      cursor: pointer;
    }

    .abstract {
      // display: -webkit-box;
      // 超过两行省略号显示
      // -webkit-line-clamp:2;
      white-space: normal;
      /* overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical; */
    }
  }
</style>
